<template>
  <section class="ll-box">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Fulfillment') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Inventory') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- Filters -->
    <b-row class="mb-1">
      <b-col cols="2">
        <b-input-group class="input-group-merge ml-1 w-100">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Code or Barcode"
          />
        </b-input-group>
      </b-col>
      <b-col cols="2">
        <b-input-group class="input-group-merge ml-1 w-100">
          <b-input-group-prepend is-text>
            <feather-icon
              icon="SearchIcon"
              class="text-muted"
            />
          </b-input-group-prepend>
          <b-form-input
            placeholder="SKU Name"
          />
        </b-input-group>
      </b-col>
      <b-col cols="2">
        <v-select
          placeholder="Client"
          v-if="isAdmin"
        ></v-select>
      </b-col>
      <b-col cols="2">
      </b-col>
      <b-col cols="4" class="d-flex justify-content-end">
        <b-button
         variant="secondary"
        >
          Download
        </b-button>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      class="position-relative ll-table"
      :items="rows"
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      :striped="true"
      :hover="true"
      :borderless='true'
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :sticky-header="true"
    >
    </b-table>
    <div class="d-flex justify-content-center flex-wrap ll-page">
      <div class="d-flex align-items-center mb-0 mt-1 mr-1">
        <b-form-select
          v-model="size"
          :options="pageOptions"
          class="mx-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> item per page </span>
      </div>
      <div class="mt-1">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="size"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BTooltip,
  BCard, BCardBody, BRow, BCol, BSidebar, VBToggle, BTable,
  BInputGroupPrepend, BInputGroup, BBreadcrumb, BBreadcrumbItem,
  BBadge, BSpinner, BDropdown, BDropdownItem, BLink, BModal, VBModal,
  BForm, VBTooltip, BProgress, BProgressBar, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import permission from '@core/directives/permission/index.js'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    flatPickr,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BForm,
    BProgress,
    BProgressBar,
    BFormRadio,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
    permission,
  },
  props: {
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null,
      size: 50,
      totalSize: null,
      totalPage: null,
      isAdmin: false,
      rows: [],
      columns: [
        { key: 'invoiceType', label: this.$t('Client'), sortable: true },
        { key: 'feeType', label: this.$t('Name') },
        { key: 'status', label: this.$t('SKU Code') },
        { key: 'tkID', label: this.$t('Warehouse') },
        { key: 'refNo', label: this.$t('Batch No')+'.' },
        { key: 'refNoType', label: this.$t('Expiry Date') },
        { key: 'tkQty', label: 'Total Qty' },
        { key: 'amount', label: this.$t('Awaiting') },
        { key: 'createdAt', label: this.$t('Pending to Put Away') },
        { key: 'operator', label: this.$t('Usable') },
        { key: 'action', label: this.$t('Reserved') },
        { key: 'Available', label: this.$t('Available For Sale') },
        { key: 'Allocated', label: this.$t('Allocated') },
        { key: 'Hold', label: this.$t('On Hold') },
        { key: 'Exception', label: this.$t('Exception') },
      ],
    }
  },
  computed: {},
  watch: {
  },
  created() {
  },
  mounted() {
    this.ability()
  },
  methods: {
    ability() {
      const userData = JSON.parse(localStorage.getItem('userInfo'))
      userData.roleDtos.map(i=>{
        if (i.name == 'Super Admin') {
          this.isAdmin = true
        }
      })
    },
    handlePageChange(){},
    handleChangePage(){},
  }
}
</script>
<style scoped>
.ll-box{
  justify-content: space-between;
}
</style>
<style>
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
